<template>
  <div class="menus" style="padding: 12px 100px">
    <el-form
      :inline="true"
      :model="froms"
      ref="froms"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        v-show="shows"
        label="id"
        prop="name"
        :label-width="formwidth"
      >
        <el-input v-model="froms.id"></el-input>
      </el-form-item>
      <el-form-item label="每日登录积分" prop="name" :label-width="formwidth">
        <el-input v-model="froms.login"></el-input>
      </el-form-item>
      <!-- <el-form-item label="赠送类型" prop="delivery" :label-width="formwidth">
                <el-input  v-model="froms.logintype"></el-input>
            </el-form-item> -->
      <el-form-item
        label="学习文章上限"
        prop="delivery"
        :label-width="formwidth"
      >
        <el-input v-model="froms.article"></el-input>
      </el-form-item>
      <el-form-item label="奖励积分" prop="delivery" :label-width="formwidth">
        <el-input v-model="froms.articlecredit"></el-input>
      </el-form-item>

      <!-- <el-form-item label="赠送类型"  :label-width="formwidth">
                <el-input  v-model="froms.articletype"></el-input>
            </el-form-item> -->
      <el-form-item label="学习时长控制" :label-width="formwidth">
        <el-input v-model="froms.articlenum"></el-input>
      </el-form-item>
      <el-form-item label="每日学习文章时长上限" :label-width="formwidth">
        <el-input v-model="froms.articletime"></el-input>
      </el-form-item>
      <el-form-item label="时长奖励积分" :label-width="formwidth">
        <el-input v-model="froms.articletimecredit"></el-input>
      </el-form-item>
      <!-- <el-form-item label="赠送类型"  :label-width="formwidth">
			    <el-input  v-model="froms.articletimetype"></el-input>
			</el-form-item> -->
      <el-form-item label="学习最多获取多少积分" :label-width="formwidth">
        <el-input v-model="froms.dailyMaximum"></el-input>
      </el-form-item>
      <!-- <el-row>
        <el-col :span="12"
          ></el-col>
      </el-row> -->
      <el-form-item>
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      style="margin-top: 15px"
      @click="submitForm('froms')"
      >保存</el-button
    >
  </div>
</template>



<script>
import request from "@/utils/request.js";

export default {
  data() {
    return {
      formwidth: "180px",
      htmls: "",
      froms: {},
      shows: false,
    };
  },
  created() {
    this.info();
  },
  methods: {
    info() {
      return request({
        url: "configCredit/list",
        method: "get",
      }).then((res) => {
        if (res.status == 200) {
          this.froms = res.data;
          //  console.log(this.froms);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            url: "configCredit/update",
            method: "put",
            data: this.froms,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.info();
            }
          });
        } else {
          //  console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  width: 70%;
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
 
